<template>
    <div>
        <div class="box-curves">
    <v-card-title>
     Matrices
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers_matrice"
      :items="matrice"
      :search="search"
    >
      <template v-slot:item="{ item }">
          <tr :class="{'highlight': item.updated}">
            <td v-for="header in headers_matrice" :key="header.value">
              <div v-if="header.value === 'action'">
                <!-- Slot personnalisé pour l'action avec l'image -->
              </div>
              <div v-else>
                <!-- Affichage de la valeur de l'élément pour les cellules qui ne sont pas l'action -->
                {{ item[header.value] }}
              </div>
            </td>
          </tr>
        </template>
    </v-data-table>
  </div>
    </div>
</template>

<script>
import { getDataLastId, getDurationCapteurs, getStockage } from '../functions/get-data'
import { formatDuration } from '../functions/format';
import { switchToken } from '../functions/test-token';
import moment from 'moment';
moment.locale('fr');
export default {
	data(){
		return{
			search: '',
            matrice_id_name :[],
            matrice_id: [],
            val_ia_matrice: 0,
            matrice: [],
            headers_matrice: [
            {
                text: 'Matrice',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Cloud', value: 'wifi' },
            { text: 'Stockage', value: 'stockage' },
            { text: 'Durée utilisation', value: 'durée'},
            { text: 'Dernière utilisation', value: 'last_used' }
            ],
            interval1: null,
            interval2: null,
            id_memory2: [],
		}
	},
	async mounted(){
        const GetMatrice = await Promise.all([this.getTagAvailable()]);
        if (GetMatrice) {
          this.CreateTable2();
          this.getStockageMatrice();
          this.getDurationMatrice();
          this.interval1 = setInterval(this.getDataforMatrice, 1000);
        }
        this.interval2 = setInterval(() => {
          this.getStockageMatrice();
          this.getDurationMatrice();
          // Ajoutez autant d'appels de fonctions que nécessaire
        }, 50000);
	},
    methods:{
      async getTagAvailable(){
          const user = JSON.parse(localStorage.getItem('user'))
          const accessToken = localStorage.getItem('accessToken');
          const email = user.email
          const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
          const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          });
          return fetch(url, { headers: headers })
            .then(res => {
              if (res.status === 401) {
                switchToken().then(() => {
                  // Refaites la requête avec le nouveau token
                  this.getTagAvailable();
                });
              }
              return res.text();
            })
            .then(async (result) => {
                const data = JSON.parse(result);
                this.matrice_id = data.matrice_attributed
                this.matrice_name = data.matrice_renamed
                for(let i=0 ; i< this.matrice_id.length ; i++){
                  if(this.matrice_name[i] == "noname"){
                      this.matrice_id_name.push(this.matrice_id[i])
                  }else{
                      this.matrice_id_name.push(this.matrice_name[i])
                  }
                }
            })
            .catch(error => {
                console.log(error)
            });
    },
    
    async CreateTable2() {
        if (this.matrice_id && this.matrice_id_name && this.matrice_id.length === this.matrice_id_name.length) {
            for (let i = 0; i < this.matrice_id.length; i++) {
                this.matrice.push({
                    tag: this.matrice_id[i],
                    name: this.matrice_id_name[i],
                    wifi: '',
                    stockage: 0,
                    durée: 0,
                    last_used: 0,
                    id_memo: 0,
                });
            }
        } else {
            console.error("Matrice data not properly initialized.");
            // Handle the case where data is not properly initialized.
        }
    },

    async getStockageMatrice() {
        let results = [];
        for (let i = 0; i < this.matrice.length; i++) {
            const tag_id = this.matrice[i].tag;
            const result = await getStockage(tag_id)
            if (result) {
                results.push(result);
            }
        }

        for (let i = 0; i < results.length; i++) {
            if (results[i] && results[i].length > 0) {
                let duration = results[i][0].table_size;
                // Affectation de la durée formatée à la propriété 'durée' de l'objet correspondant
                this.matrice[i].stockage = duration;
            } else {
                // Handle the case where result[i] is null or empty
                this.matrice[i].stockage = 0; // or set it to an appropriate default value
            }
        }
        this.show = 1;
    },

    async getDurationMatrice() {
        for (let i = 0; i < this.matrice.length; i++) {
          const tag_id = this.matrice[i].tag;
          const total_duration = await getDurationCapteurs(tag_id)
          this.matrice[i].durée = await formatDuration(total_duration)
        }
		},
		
    async getDataforMatrice(){
      for(let i = 0; i < this.matrice.length; i++){
        const data = await getDataLastId(this.matrice[i].tag);
        this.matrice[i].last_used = this.formatDate(data[0].received_on);
        const previousId = this.matrice[i].id_memo || 0; // Assurez-vous que previousId a une valeur par défaut
        this.matrice[i].id_memo = data[0]?.id; // Mettez à jour l'ID mémorisé avec le nouvel ID
        if (previousId) {
          const isNewData = data[0].id > previousId;
          this.matrice[i].wifi = isNewData ? 'présent' : 'absent';
          this.matrice[i].updated = isNewData;
        }
        this.matrice[i].id_memo = data[0].id
      }
      await this.moveUpdatedTagsToTop();

    },
    async moveUpdatedTagsToTop() {
      const updatedTags = [];
      const notUpdatedTags = [];
      this.matrice.forEach(tag => {
        if (tag.updated) {
          updatedTags.push(tag);
        } else {
          notUpdatedTags.push(tag);
        }
      });
      this.matrice = [...updatedTags, ...notUpdatedTags];
    },
    formatDate(date) {
        return moment(date).format('LL, LT');
    },
    },
    beforeDestroy() {
      clearInterval(this.interval1);
      clearInterval(this.interval2);
    },
}
</script>

<style>

.highlight {
  position: relative;
  z-index: 1;
}

.highlight::after {
  content: "";
  background-color: var(--vertfonce);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  animation: fadeOutOpacity 2s infinite;
}

@keyframes fadeOutOpacity {
  0%, 100% {
    opacity: 0.5;
  }
  25%, 75% {
    opacity: 0.2;
  }
}
</style>